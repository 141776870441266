import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

// Import your translation files
import translationSR from './locales/sr/translation.json';
import translationEN from './locales/en/translation.json';
import translationFR from './locales/fr/translation.json';
import translationNL from './locales/nl/translation.json';



// The translations
const resources = {
  sr: {
    translation: translationSR
  },
  en: {
    translation: translationEN
  },
  fr: {
   translation: translationFR  
  }, nl: {
    translation: translationNL  }
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
