import card from "./Asset 13.png"

const FooterData = [
    {
        image: "fa-solid fa-credit-card",
        title: "kartica",
    },
    {
        image: "fa-solid fa-file-invoice",
        title: "faktura", 
    },
    {
        image: "fa fa-laptop",
        title: "onlajn",
    },
    {
        image: "fa-solid fa-money-bill",
        title: "keš",
    }
]

export default FooterData